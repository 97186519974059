function updateOptions(options) {
	let update = {
		...options,
		// credentials: `same-origin`,
		credentials: 'include',
	}

	if (window.csrf) {
		update.headers = {
			...update.headers,
			'X-CSRF-TOKEN': window.csrf,
		}
	}
	//
	// if (options?.credentials) {
	// 	update = options
	// }

	if (options?.method?.toLocaleLowerCase() === 'post') {
		update.headers = {
			...update.headers,
			Accept: 'application/json',
		}
	}

	if (update.body instanceof FormData) {
		// update.body.append('_method', 'PUT')
	} else if (typeof update.body === 'object') {
		// && !(update.body instanceof FormData)
		update.body = JSON.stringify(update.body)

		update.headers = {
			...update.headers,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
		}
	}

	return update
}

const MAX_NB_RETRY = 5
const RETRY_DELAY_MS = 200

async function fetchRetry(input, init) {
	let retryLeft = MAX_NB_RETRY
	while (retryLeft > 0) {
		try {
			return await fetch(input, init)
		} catch (err) {
			await sleep(RETRY_DELAY_MS)
		} finally {
			retryLeft -= 1
		}
	}
	throw new Error(`Too many retries to ${input}`)
}

function sleep(delay) {
	return new Promise(resolve => setTimeout(resolve, delay))
}

export default function fetcher(url, options) {
	return fetchRetry(url, updateOptions(options))
}
